import groq from 'groq'

import { frameBuilderPortableTextFragment } from './content'

const frameBuilderStringsFragment = groq`
  artworkSizeStepTitle,
  artworkSizePlaceholder,
  artworkSizeSizeNotValid,
  artworkSizeStandardSizeLabel,
  artworkSizeCustomOptionLabel,
  artworkSizeWidthLabel,
  artworkSizeHeightLabel,
  artworkSizeWidthAndHeightDescription[] {
    ${frameBuilderPortableTextFragment}
  },
  artworkSizeNoSelection,
  artworkSizeNoWidthSelected,
  artworkSizeNoHeightSelected,
  artworkSizeOutOfProfileLimits,
  artworkSizeStandardSizeMessage,
  artworkSizeStandardSizeLinkText,
  artworkSizeStepFooter[] {
    ${frameBuilderPortableTextFragment}
  },
  artworkPreviewStepTitle,
  artworkPreviewPlaceholder,
  artworkPreviewArtworkLabel,
  artworkPreviewArtworkDescription[] {
    ${frameBuilderPortableTextFragment}
  },
  artworkPreviewBackgroundColorLabel,
  artworkPreviewBackgroundColorPlaceholder,
  artworkPreviewNoSelection,
  artworkPreviewStepFooter[] {
    ${frameBuilderPortableTextFragment}
  },
  frameStyleStepTitle,
  frameStylePlaceholder,
  frameStyleProfileNotValid,
  frameStyleTextureNotValid,
  frameStyleTextureLabel,
  frameStyleTextureDescription[] {
    ${frameBuilderPortableTextFragment}
  },
  frameStyleProfileLabel,
  frameStyleProfileDescription[] {
    ${frameBuilderPortableTextFragment}
  },
  frameStyleProfileReadMore,
  frameStyleProfileReadMoreUrl,
  frameStyleStepFooter[] {
    ${frameBuilderPortableTextFragment}
  },
  passepartoutStepTitle,
  passepartoutPlaceholder,
  passepartoutSizeNotValid,
  passepartoutColorNotValid,
  passepartoutSizeLabel,
  passepartoutSizeDescription[] {
    ${frameBuilderPortableTextFragment}
  },
  passepartoutColorLabel,
  passepartoutColorDescription[] {
    ${frameBuilderPortableTextFragment}
  },
  passepartoutStepFooter[] {
    ${frameBuilderPortableTextFragment}
  },
  glassStepTitle,
  glassPlaceholder,
  glassTypeNotValid,
  glassTypeLabel,
  glassTypeDescription[] {
    ${frameBuilderPortableTextFragment}
  },
  glassStepFooter[] {
    ${frameBuilderPortableTextFragment}
  },
  spacerStepTitle,
  spacerStepPlaceholder,
  spacerStepColorLabel,
  spacerStepFooter[] {
    ${frameBuilderPortableTextFragment}
  },
  discountPercentLabel,
  controlCancelLabel,
  controlOkLabel,
  controlChooseProductHint,
  controlAddToCartHint,
  controlQuantityLabel,
  controlPriceLabel,
  addProductError,
`

const frameBuilderProductStringsFragment = groq`
  specificationBarLabel,
  specificationFrameSizeLabel,
  specificationNoGlassLabel,
  specificationPassepartoutLabel,
  specificationPassepartoutOutLabel,
  specificationPassepartoutInLabel,
  specificationBackboardSizeLabel,
  specificationProtectiveLayerSizeLabel,
  specificationTrueLabel,
  specificationFalseLabel,
`

export const frameBuilderStringsObjectFragment = groq`
  "strings": *[
    _type == "frameBuilderStrings" &&
    locale == $locale
  ][0] {
    ${frameBuilderStringsFragment}
  },
`

export const frameBuilderProductStringsObjectFragment = groq`
  "strings": *[
    _type == "frameBuilderStrings" &&
    locale == $locale
  ][0] {
    ${frameBuilderProductStringsFragment}
  },
`
